.multi-value-normal {
    background-color: rgba(199, 255, 234, 0.418);
    
  }
  
  .multi-value-deleted {
    background-color: rgba(255, 0, 0, 0.301);
  }
  
  .multi-value-suspended {
    background-color: rgba(255, 255, 0, 0.336);
  }
  